
// @ts-nocheck
import moment from "moment";
import Vue from "vue";
import { mapMutations } from "vuex";

import { SET_TIME_NOW } from "@/store/types/mutations";

export default Vue.extend({
  render: () => null,
  data() {
    return {
      minuteTimer: null as number | null,
    };
  },
  created() {
    this.setupTimeInterval();
  },
  beforeDestroy() {
    this.minuteTimer && clearInterval(this.minuteTimer);
  },
  methods: {
    ...mapMutations([SET_TIME_NOW]),
    setupTimeInterval() {
      const millisecondsUntilMinuteChange =
        (60 - parseInt(moment().format("ss"), 10)) * 1000;

      setTimeout(() => {
        this.SET_TIME_NOW();

        this.minuteTimer = setInterval(() => {
          this.SET_TIME_NOW();
        }, 60000);
      }, millisecondsUntilMinuteChange);
    },
  },
});
